//BlochainData -> Full data about <blockchain/> blockchain and <ViewCourseDeatils1/>


// import TopBlock from '../../academysite/src/assets/topcourseBlockchain.jpg'
import image1 from '../../../assets/blocktechnology.webp';
import image2 from '../../../assets/cryptocurrencies-and-blockchainTech.jpg';
import image3 from '../../../assets/blockchaindevprofessional.jpg'
import image4 from '../../../assets/Solidity-Programming-Language.jpg';
import image5 from '../../../assets/blockchain-trainer.png';

export const courses=[
    {
        id:1,
        image: image1,
        title: "Blockchain Developer Fundamental",
        para: "Ratting: 4.6 ★ (7,508 reviews)|95%",
        paragraph1: "Blockchain Fundamentals utilizes a hybrid learning approach to help you build and demonstrate an understanding of blockchain concepts, usage and considerations. Learn how to leverage yourin-demand knowledge and ability in blockchain to elevate your IT career.",
        paragraph2: "Beyond matters of trust, blockchain delivers even more business benefits, including the cost savings from increased speed, efficiency and automation. By greatly reducing paperwork and errors, blockchain significantly reduces overhead and transaction costs, and reduces or eliminates the need for third parties or middlemen to verify transactions.",
   
        name:'Learn Fundemantals of Blockchain Development',
        description:'Learn about the fundamentals of blockchain development, relevant skills, and technical FAQ.',
        heading:'About this course',
        deshead:'Blockchain technology is frequently sought after for efficient database management. It aids in organizing and storing information in a format easily comprehensible to computers. Unlike traditional databases, blockchain formats data digitally, assembling it into clusters or blocks. ',
        heading1:'Skills you will gain',

        desheading1:[
            '⍟ Understanding Blockchain of Fundemantal concepts',
            '⍟ Learn essential skills.',
            '⍟ Familiarize yourself with the blockchain development industry.',
        ],

         heroSec:[
            {
              id:1,
              symbol:'📝',
              content:'Lessons-09',
            },
            {
                id:2,
                symbol:'🕜',
                content:'Timing-9.30 to 1.30',
              },

            {
              id:3,
              symbol:'📅',
              content:'Months-3',
            },
            {
              id:4,
              symbol:'🎖',
              content:'Fee-15000',
            },
            {
              id:5,
              symbol:'⤴️',
              content:'Skill Level-Beginner',
            },
            {
              id:6,
              symbol:'🏅',
              content:'Certificate-Yes',
            },
            {
              id:7,
              symbol:'🗒 ',
              content:'Assessments-Yes',
            },
          ],
        syllabusheading:'Syllabus',
        syllabus:'15 lessons • 14 projects • 8 quizzes',
        content:[
            {
                id: 1,
                name: 'Lesson 1:Introduction to Blockchain Development ',
                content: [
                    'Basics of Blockchain Technology ',
                    'Development Tools and Environments',
                    'Setting Up Development Environment',
                ]
            },
            {
                id: 2,
                name: 'Lesson 2:Ethereum and Smart Contracts ',
                content: [
                    'Ethereum Platform Overview',
                    'Solidity Programming Language',
                    'Writing and Deploying Smart Contracts',
                    'Interacting with Smart Contracts',
        
        
        
                ]
            },
            {
                id: 3,
                name: 'Lesson 3: Decentralized Application (DApp) Architecture',
                content: [
                    'Understanding DApps',
                    'DApp Components (Frontend, Backend, Smart Contracts)',
                    'Web3.js: Interaction between DApp and Blockchain',
        
        
        
                ]
            },
            {
                id: 4,
                name: 'Lesson 4:Building a Simple DApp',
                content: [
                    'Ideation and Project Planning',
                    'Creating the Frontend (HTML, CSS, JavaScript)',
                    'Developing the Smart Contract',
                    'Integrating Web3.js',
                    'Testing and Deployment',
                    'User Interface Design and User Experience (UI/UX)',
        
        
        
                ]
            },
            {
                id: 5,
                name: 'Lesson 5: Advanced Topics ',
                content: [
                    'Security Best Practices (Avoiding Common Vulnerabilities)',
                    'Gas Optimization',
                    'IPFS and Decentralized Storage',
                    'Oracles and Real-World Data Integration',
                    'Scaling Solutions (e.g., Layer 2)',
        
        
        
                ]
            },
            {
                id: 6,
                name: 'Lesson 6:Ethereum Development Ecosystem ',
                content: [
                    'Ethereum Improvement Proposals (EIPs)',
                    'Ethereum Development Tools (Truffle, Remix, Hardhat)',
                    'Other Blockchain Platforms (e.g., Binance Smart Chain, Solana)',
        
        
                ]
            },
            {
                id: 7,
                name: 'Lesson 7: Final DApp Project and Presentations ',
                content: [
                    'Participants work on a more complex DApp project',
                    'Project presentations and feedback',
                ]
            },
            {
                id: 8,
                name: 'Lesson 8: Conclusion and Next Steps',
                content: [
                    'Review of Key Concepts',
                    'Discussing Career Opportunities in Blockchain Development',
        
        
                ]
            },
            {
                id: 9,
                name: 'Lesson 9: Final test and Evaluation ',
                content: [
                    'Final Certification delivery',
        
                ]
            },
        
        ]
        

    },
    {
        id:2,
            image: image2,
            title: "Blockchain Developer Professional",
            para: "Ratting: 4.5 ★ (7,508 reviews)|95%",
            paragraph1: "The objective of this Intermediate Level Blockchain Developer Course is to take participants to the next level of blockchain development.",
            paragraph2: "A blockchain developer develops smart contracts on Ethereum-based protocols and builds consensus algorithms.A blockchain developer creates various applications for blockchain networks that are secure and efficient for their customers .",
       

        name:'Learn Blockchain Developer Professional',
        description:'Learn about the blockchain developer professional course, relevant skills, and technical FAQ.',
        heading:'About this course',
        deshead:'The Blockchain Developer Professional The objective of this Intermediate Level Blockchain Developer Course is to take participants to the next level of blockchain development.By the end of the course, participants should be able to build and deploy more advanced decentralized applications (DApps), create NFTs, and understand the principles of decentralized finance (DeFi) projects. ',
        heading1:'Skills you will gain',

        desheading1:[
            '⍟ Understanding Blockchain Developer Professional concepts',
            '⍟ Learn essential skills.',
            '⍟ Familiarize yourself with the blockchain development industry.',
        ],

         heroSec:[
            {
              id:1,
              symbol:'📝',
              content:'Lessons-09',
            },
            {
                id:2,
                symbol:'🕜',
                content:'Timing-9.30 to 1.30',
              },

            {
              id:3,
              symbol:'📅',
              content:'Months-3',
            },
            {
              id:4,
              symbol:'🎖',
              content:'Fee-15000',
            },
            {
              id:5,
              symbol:'⤴️',
              content:'Skill Level-Beginner',
            },
            {
              id:6,
              symbol:'🏅',
              content:'Certificate-Yes',
            },
            {
              id:7,
              symbol:'🗒 ',
              content:'Assessments-Yes',
            },
          ],
        syllabusheading:'Syllabus',
        syllabus:'15 lessons • 14 projects • 8 quizzes',

        content :[

            {
                id: 1,
                name: 'Lesson 1: Review of Blockchain Basics  ',
                content: [
                    'A brief recap of blockchain fundamentals from the Beginner Level course. ',
        
                ]
            },
            {
                id: 2,
                name: 'Lesson 2:Advanced Smart Contract Development  ',
                content: [
                    'Building Complex Smart Contracts',
                    'Using Libraries and Inheritance',
                    'Advanced Solidity Concepts',
                    'Debugging and Testing',
        
        
        
                ]
            },
            {
                id: 3,
                name: 'Lesson 3: Building Advanced DApps ',
                content: [
                    'Architecting DApps with Complex Functionality',
                    'Frontend and Backend Development',
                    'User Authentication and Permissions',
                    'Data Storage and Retrieval',
        
        
        
        
                ]
            },
            {
                id: 4,
                name: 'Lesson 4:Non-Fungible Tokens (NFTs) ',
                content: [
                    'Understanding NFTs and Their Use Cases',
                    'Creating and Minting NFTs',
                    'Integrating NFTs into DApps',
                    'NFT Marketplaces and Standards (e.g., ERC-721, ERC-1155)',
        
        
        
        
                ]
            },
            {
                id: 5,
                name: 'Lesson 5: Decentralized Finance (DeFi)',
                content: [
                    'Introduction to DeFi',
                    'Building a Basic DeFi Application',
                    'Liquidity Pools and Automated Market Makers (AMMs)',
                    'Flash Loans and Yield Farming',
        
        
        
        
        
        
                ]
            },
            {
                id: 6,
                name: 'Lesson 6:Advanced DeFi Projects ',
                content: [
                    'Developing Complex DeFi Protocols',
                    'Integrating Oracle Services',
                    'Security Considerations in DeFi',
                    'Auditing and Testing DeFi Projects',
        
        
        
        
        
        
        
                ]
            },
            {
                id: 7,
                name: 'Lesson 7: Final Projects and Presentations ',
                content: [
                    'Participants work on advanced DApps, NFT, or DeFi projects',
                    ' Project presentations and feedback',
                ]
            },
            {
                id: 8,
                name: 'Lesson 8: Conclusion and Next Steps ',
                content: [
                    'Review of Key Concepts',
                    ' Discussing Career Opportunities in Advanced Blockchain Development',
                    'Online Test Evaluation',
                    ' Final Certification delivery',
                ]
            },
        
        
        ]
    },
    {
        id:3,

        image: image3,
        title: "Blockchain Developer Expert",
        para: "Ratting: 4.6 ★ (7,508 reviews)|95%",
        paragraph1:'The demand for blockchain expert skills keeps rising every single day. At present, many enterprise companies are looking for professionals with the blockchain knowledge base. More so, as there are only a handful of professionals who knows how to handle blockchain networks, the necessity of blockchain experts is at an all-time high.',
        paragraph2: " Usually, the pay rate for this position is relatively high, even can even higher than typical software developers.",
   
        name:'Learn about Blockchain Developer Expert',
        description:'The objective of the Advanced Blockchain Development course is to provide participants with a deep understanding of advanced blockchain concepts and technologies. ',
        heading:'About this course',
        deshead:'Blockchain Developer Expert course, helps you to itally,deep understanding of advanced blockchain concepts and technologies. By the end of the course, participants should be able to develop complex applications on various blockchain platforms, understand the intricacies of DAOs, and work with different distributed ledger technologies. ',
        heading1:'Skills you will gain',

        desheading1:[
            '⍟ Understanding the concepts of Blockchain Developer Expert',
            '⍟ Learn essential skills.',
            '⍟ Familiarize yourself with the blockchain development industry.',
        ],

         heroSec:[
            {
              id:1,
              symbol:'📝',
              content:'Lessons-09',
            },
            {
                id:2,
                symbol:'🕜',
                content:'Timing-9.30 to 1.30',
              },

            {
              id:3,
              symbol:'📅',
              content:'Months-3',
            },
            {
              id:4,
              symbol:'🎖',
              content:'Fee-15000',
            },
            {
              id:5,
              symbol:'⤴️',
              content:'Skill Level-Beginner',
            },
            {
              id:6,
              symbol:'🏅',
              content:'Certificate-Yes',
            },
            {
              id:7,
              symbol:'🗒 ',
              content:'Assessments-Yes',
            },
          ],
        syllabusheading:'Syllabus',
        syllabus:'15 lessons • 14 projects • 8 quizzes',

        content :[

            {
                id: 1,
                name: 'Lesson 1: Review of Blockchain Basics  ',
                content: [
                    'A brief recap of blockchain fundamentals from the Beginner Level course. ',
        
                ]
            },
            {
                id: 2,
                name: 'Lesson 2:Advanced Smart Contract Development  ',
                content: [
                    'Building Complex Smart Contracts',
                    'Using Libraries and Inheritance',
                    'Advanced Solidity Concepts',
                    'Debugging and Testing',
        
        
        
                ]
            },
            {
                id: 3,
                name: 'Lesson 3: Building Advanced DApps ',
                content: [
                    'Architecting DApps with Complex Functionality',
                    'Frontend and Backend Development',
                    'User Authentication and Permissions',
                    'Data Storage and Retrieval',
        
        
        
        
                ]
            },
            {
                id: 4,
                name: 'Lesson 4:Non-Fungible Tokens (NFTs) ',
                content: [
                    'Understanding NFTs and Their Use Cases',
                    'Creating and Minting NFTs',
                    'Integrating NFTs into DApps',
                    'NFT Marketplaces and Standards (e.g., ERC-721, ERC-1155)',
        
        
        
        
                ]
            },
            {
                id: 5,
                name: 'Lesson 5: Decentralized Finance (DeFi)',
                content: [
                    'Introduction to DeFi',
                    'Building a Basic DeFi Application',
                    'Liquidity Pools and Automated Market Makers (AMMs)',
                    'Flash Loans and Yield Farming',
        
        
        
        
        
        
                ]
            },
            {
                id: 6,
                name: 'Lesson 6:Advanced DeFi Projects ',
                content: [
                    'Developing Complex DeFi Protocols',
                    'Integrating Oracle Services',
                    'Security Considerations in DeFi',
                    'Auditing and Testing DeFi Projects',
        
        
        
        
        
        
        
                ]
            },
            {
                id: 7,
                name: 'Lesson 7: Final Projects and Presentations ',
                content: [
                    'Participants work on advanced DApps, NFT, or DeFi projects',
                    ' Project presentations and feedback',
                ]
            },
            {
                id: 8,
                name: 'Lesson 8: Conclusion and Next Steps ',
                content: [
                    'Review of Key Concepts',
                    ' Discussing Career Opportunities in Advanced Blockchain Development',
                    'Online Test Evaluation',
                    ' Final Certification delivery',
                ]
            },
        
        
        ]
    },
    {
        id:4,
            image: image4,
            title: "A Complete Solidity for Smart Programming",
            para: "Ratting: 4.2 ★ (7,508 reviews)|95%",
            paragraph1: "Solidity is the main programming language for writing smart contracts for the Ethereum blockchain. It is a contract-oriented language, which means that smart contracts are responsible for storing all of the programming logic that transacts with the blockchain. It's a high-level programming language that looks a lot like JavaScript, Python, and C++. ",
            paragraph2: "It's designed to run on the Ethereum Virtual Machine (EVM), which is hosted on Ethereum Nodes that are connected to the blockchain. ",
       

        name:'A Complete Solidity For Smart Programmin.',
        description:'The objective of the Complete Solidity Programming course is to provide participants with a thorough understanding of Solidity, the smart contract programming language for Ethereum. ',
        heading:'About this course',
        deshead:' To learn Solidity programming language and for experienced Solidity developers looking to gain a deeper understanding of the language. The following Solidity tutorial explains the basic and advanced concepts of Solidity programming language and provides a firm foundation in the language that will enable you to begin developing your own projects and expanding on your knowledge. ',
        heading1:'Skills you will gain',

        desheading1:[
            '⍟ Understanding the concepts of a complete solidity for smart programming.',
            '⍟ Learn essential skills.',
            '⍟ Familiarize yourself with the blockchain development industry.',
        ],

         heroSec:[
            {
              id:1,
              symbol:'📝',
              content:'Lessons-09',
            },
            {
                id:2,
                symbol:'🕜',
                content:'Timing-9.30 to 1.30',
              },

            {
              id:3,
              symbol:'📅',
              content:'Months-3',
            },
            {
              id:4,
              symbol:'🎖',
              content:'Fee-15000',
            },
            {
              id:5,
              symbol:'⤴️',
              content:'Skill Level-Beginner',
            },
            {
              id:6,
              symbol:'🏅',
              content:'Certificate-Yes',
            },
            {
              id:7,
              symbol:'🗒 ',
              content:'Assessments-Yes',
            },
          ],
        syllabusheading:'Syllabus',
        syllabus:'15 lessons • 14 projects • 8 quizzes',

        content: [

            {
                id: 1,
                name: 'Lesson 1: Solidity Fundamentals  ',
                content: [
                    'Introduction to Solidity and Ethereum',
                    'Variables, Data Types, and Functions',
                    'Control Structures (if, else, loops) ',
                    'Mapping and Arrays',
                    'Events and Error Handling',
        
        
                ]
            },
            {
                id: 2,
                name: 'Lesson 2:: Intermediate Solidity  ',
                content: [
                    'Contract Inheritance and Libraries',
                    'Smart Contract Deployment and Interaction',
                    'Function Modifiers and Access Control',
                    'State Variables and Storage Patterns',
                    'Token Standards (ERC-20, ERC-721)',
                    
        
        
                ]
            },
            {
                id: 3,
                name: 'Lesson 3:Advanced Solidity ',
                content: [
                   
        
        
        
                ]
            },
            {
                id: 4,
                name: 'Lesson 4: Solidity Security Best Practices',
                content: [
                   
        
        
        
                ]
            },
            {
                id: 5,
                name: 'Lesson 5: Handling Ether and Transactions ',
                content: [
                    
        
        
        
        
        
        
                ]
            },
            {
                id: 6,
                name: 'Lesson 6:Oracles and External Data ',
                content: [
                    
        
        
        
        
        
        
                ]
            },
            {
                id: 7,
                name: 'Lesson 7: Building Decentralized Applications (DApps)',
                content: [
                    
        
                ]
            },
            {
                id: 8,
                name: 'Lesson 8: Developing Complex Smart Contracts',
                content: [
                    
        
                ]
            },
            {
                id: 9,
                name: 'Lesson 9: DApp Development and Capstone Project ',
                content: [
                    
        
                ]
            },
        
            {
                id: 10,
                name: 'Lesson 10: Setting Up a Development Environment for DApps',
                content: [
                    
        
                ]
            },
            {
                id: 11,
                name: 'Lesson 11: Interfacing Smart Contracts with Web3.js',
                content: [
                    
        
                ]
            },
            {
                id: 12,
                name: 'Lesson 12: Building a Simple DApp',
                content: [
                    
        
                ]
            },
            {
                id: 13,
                name: 'Lesson 13: Developing a More Complex DApp ',
                content: [
                    
        
                ]
            },
            {
                id: 14,
                name: 'Lesson 14: Capstone Project and Presentations',
                content: [
                    
        
                ]
            },
        
        
        
        
        
        ]
    },
    {
        id:5,
        image: image5,
        title: "Certified Blockchain Trainer",
        para: "Ratting: 4.4 ★ (7,508 reviews)|95%",
        paragraph1: 'Many misunderstand blockchains—even those with a strong grasp of technological concepts. For this reason, entering or transitioning into blockchain development might seem challenging. However, blockchain technology is less mysterious than its reputation suggests.',
        paragraph2: "This course helps you to be Blockchain Developer, and build your teching professional career.",
      

        name:'Certified Blockchain Trainer',
        description:'This course is eligible for Certified Blockchain Developer to become Blockchain Trainer. ',
        heading:'About this course',
        deshead:' To learn this Certified Blockchain Trainer Course for your professional journey .The following  tutorial explains the basic and advanced concepts of Blockchain Trainer and provides a firm foundation in the language that will enable you to begin developing your own projects and expanding on your knowledge. ',
        heading1:'Skills you will gain',

        desheading1:[
            '⍟ Getting a complete teaching knowledge and knowledge about Blockchain programming.',
            '⍟ Learn essential skills.',
            '⍟ Familiarize yourself with the blockchain development industry.',
        ],

         heroSec:[
            {
              id:1,
              symbol:'📝',
              content:'Lessons-09',
            },
            {
                id:2,
                symbol:'🕜',
                content:'Timing-9.30 to 1.30',
              },

            {
              id:3,
              symbol:'📅',
              content:'Months-3',
            },
            {
              id:4,
              symbol:'🎖',
              content:'Fee-15000',
            },
            {
              id:5,
              symbol:'⤴️',
              content:'Skill Level-intermediate',
            },
            {
              id:6,
              symbol:'🏅',
              content:'Certificate-Yes',
            },
            {
              id:7,
              symbol:'🗒 ',
              content:'Assessments-Yes',
            },
          ],
        syllabusheading:'Syllabus',
        syllabus:'15 lessons • 14 projects • 8 quizzes',

        content:  [

            {
                id: 1,
                name: 'Lesson 1: Blockchain in Practice  ',
                content: [
                    'Real-world Blockchain Projects.',
                    'Blockchain Implementation Challenges',
        
                ]
            },
            {
                id: 2,
                name: 'Lesson 2:Regulatory and Legal Considerations ',
                content: [
                    
        
                ]
            },
            {
                id: 3,
                name: 'Lesson 3: Blockchain Trends and Future Developments',
                content: [
                    
        
        
                ]
            },
            {
                id: 4,
                name: 'Lesson 4: Training Methodologies and Curriculum Development ',
                content: [
                    'Curriculum Design and Development ',
                    
        
        
                ]
            },
            {
                id: 5,
                name: 'Lesson 5: Other Blockchain Platforms ',
                content: [
                    'Training Material Creation',
                   
        
        
        
        
        
        
                ]
            },
            {
                id: 6,
                name: 'Lesson 6:Assessments and Evaluation        ',
                content: [
                    
        
        
        
        
        
        
        
                ]
            },
            {
                id: 7,
                name: 'Lesson 7: Facilitation and Presentation Skills ',
                content: [
                   
                ]
            },
            {
                id: 8,
                name: 'Lesson 7: Teaching Blockchain  ',
                content: [
                   'Effective Communication',
                ]
            },
            {
                id: 7,
                name: 'Lesson 7: Classroom Management ',
                content: [
                   
                ]
            },
            {
                id: 8,
                name: 'Lesson 8: Handling Questions and Challenges',
                content: [
                   
                ]
            },
            {
                id: 9,
                name: 'Lesson 9: Practical Training Exercises',
                content: [
                   
                ]
            },
            {
                id: 10,
                name: 'Lesson 10: Case Study Delivery',
                content: [
                   
                ]
            },
            {
                id: 10,
                name: 'Lesson 10: Training Assessment',
                content: [
                   
                ]
            },
        
        
        
        
            
        
        ]
    },
];