export const branchDetails = [
    {
      id: 1,
      city: "Chennai",
      address: "No.10 Eshwari Nagar Gowrivakkam, Chennai - 600 073",
    },
    {
      id: 2,
      city: "Trichy",
      address:
        "76-O, Sri Jothi Complex, 3rd Floor, N.E.E. Road, (Near Medical Association) Thillainagar, Trichy-620 018",
    },
    {
      id: 3,
      city: "Madurai",
      address:
        "No. 34, TM Nagar, Nearby Mattuthavani Bus Stand, Madurai – 625 107",
    },
    {
      id: 4,
      city: "Tirunelveli",
      address:
        "No.12/1, Vasantha Nagar, Palayankottai circle, Tirunelveli - 627 002",
    },
    {
      id: 5,
      city: "Vellore",
      address:
        "Plot no.4 first floor, Thendral Nagar Extn, Chathuvachari, Vellore - 632 009",
    },
    {
      id: 6,
      city: "Salem",
      address:
        "No,2/3, Manimegalay Street, Advatha Asram Road, Balaji Nagar, Salem - 636 004",
    },
    {
      id: 7,
      city: "Coimbatore",
      address:
        "131, DB Road, 2nd Floor, (Near Ramraj Cotton Show room) RS Puram, Coimbatore - 641 002",
    },
    {
      id: 8,
      city: "Thrissur",
      address:
        "No. 29/164/71, Suharsha tower, Shornur road, Thrissur -680001",
    },
    {
      id: 9,
      city: "Chittoor",
      address:
        "No.4-2044/B, Near Savithriamma College, Vellore road, Chittoor - 517 002",
    },
  ];